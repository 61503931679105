import { LangProvider } from "./contexts/lang/provider";
import HeaderBlock from "./components/HeaderBlock"
import FeatureBlock from "./components/FeatureBlock";
import FooterBlock from './components/FooterBlock'
// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import './assets/styles/tailwind.css';

function App() {



  return (
    <>
    <LangProvider>
      <div className="absolute w-full z-20">{/* <DefaultNavbar /> */}</div>
      <main>
        <HeaderBlock />
        <FeatureBlock />
        <FooterBlock />
      </main>
      </LangProvider>
    </>
  );
}

export default App;
