import LijLogo from "../assets/img/lij-logo.png"

export default function FooterBlock({ icon, title, children }) {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a href="../" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src={LijLogo} className="pb-4 h-24 m-auto" alt="life in japan branding" />
          <span className="ml-3 text-xl">Life in Japan</span>
        </a>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">powered by
          <a href="https://www.eggworm.jp" className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">eggworm</a>
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a href="https:/eggworm.jp" className="text-gray-500">

            ©2021 Life in Japan
          </a>
          {/* <a href="../" className="ml-3 text-gray-500">
        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
          <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
        </svg>
      </a> */}
          {/* <a href="https://www.instagram.com/eggwormagency/" className="ml-3 text-gray-500">

          </a> */}
          {/* <a href="../" className="ml-3 text-gray-500">
        <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0} className="w-5 h-5" viewBox="0 0 24 24">
          <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" />
          <circle cx={4} cy={4} r={2} stroke="none" />
        </svg>
      </a> */}
        </span>
      </div>
    </footer>

  );
}
