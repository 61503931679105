import React from "react";
import StatusCard from "./StatusCard";
import AnimatedNumber from 'react-animated-number';
import ContactBlock from "./ContactBlock"
import { LangContext } from "../contexts/lang/provider";

import LijLogo from "../assets/img/lij-logo.png"
import LitLogo from "../assets/img/lit-logo.png"
import OlLogo from "../assets/img/ol-logo.png"

import lijSample from "../assets/img/lijSample.png"
import litSample from "../assets/img/litSample.png"
import olSample from "../assets/img/olSample.png"

import postSample from "../assets/img/postSample.jpeg"

export default function WorkingSection() {
  const [state] = React.useContext(LangContext)
  return (
    <section className="pb-20 bg-white -mt-32">
      <div className="container max-w-7xl mx-auto px-4">
        <div className="flex flex-wrap relative z-50">

          {!state.active ? (<StatusCard link={"https://www.facebook.com/lifeinjapanjp"} img={LijLogo} img2={lijSample} title="Life in Japan">
            Want a first-hand experience of what year-round life in Japan is all
            about? Here’s an insight into the exciting lifestyles and creations
            of people in Japan.
          </StatusCard>) : (<StatusCard link={"https://www.facebook.com/lifeinjapanjp"} img={LijLogo} img2={lijSample} title="Life in Japan">
            日本の実生活を体験してみたくありませんか？こちらでは日本に住んでいる人々のエキサイティングな生活とその創作物についてお伝えします。
          </StatusCard>)}


          {!state.active ? (<StatusCard link={"https://www.facebook.com/lifeintokyojp"} img={LitLogo} img2={litSample} title="Life in Tokyo">
            Get inspired by Tokyo, the central hub of all things Japan. Come
            find out what makes this metropolis the greatest city in the world.
            <br />
            <br />
          </StatusCard>) : (<StatusCard link={"https://www.facebook.com/lifeintokyojp"} img={LitLogo} img2={litSample} title="Life in Tokyo">
            日本の全ての中心である東京から、なぜこの大都会が他国の追随を許さず一番であり続けるのかここで発見して下さい。
            <br />
            <br />
          </StatusCard>)}

          {!state.active ? (
            <StatusCard link={"https://www.facebook.com/otakulifejp"} img={OlLogo} img2={olSample} title="Otaku Life">
              Take an inside look into the world of the Otaku! We showcase the
              anime, manga, and cosplay content that makes Otaku culture a
              worldwide phenomena.
            </StatusCard>) : (
            <StatusCard link={"https://www.facebook.com/otakulifejp"} img={OlLogo} img2={olSample} title="Otaku Life">
              ヲタクカルチャーの世界をのぞいて見ましょう！世界を魅了し続ける日本のアニメ、マンガ、コスプレコンテンツを紹介していきます。
            </StatusCard>)}

        </div>

        <div className="flex flex-wrap items-center mt-12">

          <div className="w-full md:w-1/2 lg:w-1/2 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
            {/* <Card>
              <CardImage alt="Card Image" src={"https://images.pexels.com/photos/2365159/pexels-photo-2365159.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"} />
              <CardBody>
                <H6 color="gray">Top Notch Services</H6>
                <Paragraph color="blueGray">
                  The Arctic Ocean freezes every winter and much of the sea-ice
                  then thaws every summer, and that process will continue
                  whatever happens.
                </Paragraph>
              </CardBody>
            </Card> */}
            <div className=" bg-gray-100 flex justify-center items-center">
              <div className="max-w-s container bg-white rounded-xl shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                <div>
                  <h1 className="text-2xl mt-2 ml-4 font-bold text-gray-800 cursor-pointer hover:text-gray-900 transition duration-100">📌 Reigakuji Temple</h1>
                  <p className="ml-4 mt-1 mb-2 text-gray-700 hover:underline cursor-pointer">May 24, 2:00 PM</p>
                </div>
                <img className="w-full h-72 object-cover " src={postSample} alt="" />
                <div className="flex p-4 justify-between">
                  <div className="flex items-center space-x-2">
                    <img className="w-10 rounded-full" src={LijLogo} alt="sara" />
                    <h2 className="text-gray-800 font-bold">Life in Japan</h2>
                  </div>
                  <div className="flex space-x-2">
                    <div className="flex space-x-1 items-center">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-gray-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                        </svg>
                      </span>
                      <span>                            
                        <AnimatedNumber
                        style={{
                          transition: '0.8s ease-out',
                          transitionProperty:
                            'background-color, color'
                        }}
                        duration={24000}
                        stepPrecision={0}
                        value={10}
                        formatValue={n => `${n} `
                        } />
                      </span>
                    </div>
                    <div className="flex space-x-1 items-center">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 text-red-500 hover:text-red-400 transition duration-100 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <span> <AnimatedNumber
                        style={{
                          transition: '0.8s ease-out',
                          transitionProperty:
                            'background-color, color'
                        }}
                        duration={1200000}
                        stepPrecision={0}
                        value={1100}
                        formatValue={n => `${n} `
                        } /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/*  */}

          <div className="w-full md:w-1/2 lg:w-1/2 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
            <ContactBlock />

          </div>
        </div>
      </div>
    </section>
  );
}
