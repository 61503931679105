import React from "react";
import { LangContext } from "../contexts/lang/provider";
import H2 from '@material-tailwind/react/Heading2';
import LeadText from '@material-tailwind/react/LeadText';
import Switch from './Switch';


export default function Header(props) {
    const [state] = React.useContext(LangContext)

    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />

            <Switch props={props} />

            <div className="container max-w-8xl relative mx-auto">

                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">

                        {!state.active ? (<H2 color="white">Welcome to Life in Japan!</H2>) : (<H2 color="white">Life in Japan へようこそ！</H2>)}



                        <div className="text-gray-200">
                            {!state.active ? (<LeadText style={{ color: `white`, fontWeight: `bold` }} color="white">
                                Here’s a sneak peek into the island nation that combines modern and tradition like none other.
                            </LeadText>) : (<LeadText style={{ color: `white`, fontWeight: `bold` }} color="white">
                                ここでは他の国にはない近代と<br />伝統文化の融合した日本の<br />魅力を紹介します。
                            </LeadText>)}

                            <form
                                method="post"
                                data-netlify="true"
                                name="newsletter"
                            >
<input type="hidden" name="form-name" value="newsletter" />
                                <input type="email" id="email" name="email" placeholder="enter your email to get our newsletter! " className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                <button className="text-white bg-red-500 border-0 py-2 px-8 mt-4 focus:outline-none hover:bg-red-600 rounded text-sm">sign up</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
