import React from "react"
import { reducer, initialState } from "./reducer"

export const LangContext = React.createContext({
  state: initialState,
  toggle: () => null
})

export const LangProvider = ({ children }) => {
  const [state, toggle] = React.useReducer(reducer, initialState)

  return (
    <LangContext.Provider value={[ state, toggle ]}>
    	{ children }
    </LangContext.Provider>
  )
}