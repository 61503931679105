import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import H6 from '@material-tailwind/react/Heading6';
import Paragraph from '@material-tailwind/react/Paragraph';


export default function StatusCard({ img, img2, title, children, link }) {
    return (
        <div className="w-full md:w-4/12 px-4 flex justify-center py-6 text-center">
            <Card>
                <CardBody>
                    <img src={img2} className="pb-4 h-72 w-full object-cover m-auto" alt="life in japan branding" />
                    <H6 color="gray">{title}</H6>
                    <img src={img} className="pb-4 h-24 m-auto" alt="life in japan branding" />
                    <Paragraph color="blueGray">{children}</Paragraph>
                    <div className="flex justify-center ">
                    </div>
                </CardBody>
                <a href={link}>
                    <span
                        className="inline-flex text-red-500 hover:text-red-300 justify-center sm:justify-start">

                        Join the community {' '}   <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} className="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
                        </svg>

                    </span>
                </a>
            </Card>
        </div>
    );
}
