import React from "react"
import { LangContext } from "../contexts/lang/provider";
export default function Switch() {
    const [state, toggle] = React.useContext(LangContext)
    return (
        <div className="absolute top-2 right-4 inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
                onChange={() => {
                    toggle({ type: "toggle_button" })
                }}
                type="checkbox"
                name="toggle"
                id="toggle"
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label
                htmlFor="toggle"
                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
            {!state.active ? (
                <label
                    htmlFor="toggle"
                    className="text-xs text-white"
                >
                    日本語
                </label>
            ) : (
                <label
                    htmlFor="toggle"
                    className="text-xs text-white"
                >
                    EN
                </label>
            )}
        </div>
    );
}
